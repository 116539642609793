<template>
  <div class="modal is-active is-info">
    <div class="modal-card">
      <div class="modal-content">
        <div class="box">
          <div class="columns is-mobile">
            <div class="column">
              <p class="subtitle has-text-dark">Filter by date</p>
            </div>
            <div class="column is-narrow">
              <a class="clear-filter-text" @click="clear">Clear filter</a>
            </div>
          </div>
          <div class="container is-narrow">
            <p v-if="isCompanion" class="subtitle is-6">
              {{ availableDates }}
            </p>
            <div class="columns is-multiline">
              <div class="column is-12">
                <DatePicker
                  v-model="filter.date"
                  :inline="true"
                  :format="format"
                  :min-date="dateMin"
                  :max-date="dateMax"
                  color="#013366"
                  button-color="#013366"
                  :only-date="true"
                  :no-header="false"
                  :range="range"
                  :custom-shortcuts="shortcuts"
                />
              </div>
            </div>
            <div class="columns has-text-right">
              <div class="column">
                <span class="button is-text" @click.prevent="close">
                  Cancel
                </span>
                <button
                  type="submit"
                  class="button is-info is-rounded"
                  :class="{ 'is-loading': $wait.is('loading') }"
                  @click="save"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import * as layout from 'modules/layout/services'
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
  name: 'TableFilter',
  components: {
    DatePicker: () => import('vue-ctk-date-time-picker')
  },
  props: {
    preload: {
      type: Object,
      required: false,
      default: () => null
    },
    dateFormat: {
      type: String,
      required: false,
      default: () => 'YYYY-MM-DD'
    },
    dateMin: {
      type: [String, Date],
      required: false,
      default: () => null
    },
    dateMax: {
      type: [String, Date],
      required: false,
      default: () => null
    },
    range: {
      type: Boolean,
      required: false,
      default: () => false
    },
    isCompanion: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data: () => ({
    filter: {
      date: null
    },
    shortcuts: [
      { key: 'thisWeek', label: 'This Week', value: 'week' },
      { key: 'lastWeek', label: 'Last Week', value: '-week' },
      { key: 'last7days', label: 'Last 7 days', value: 6 },
      { key: 'last30days', label: 'Last 30 days', value: 29 },
      { key: 'thisMonth', label: 'This Month', value: 'month' },
      { key: 'lastMonth', label: 'Last Month', value: '-month' },
      { key: 'thisYear', label: 'This Year', value: 'year' },
      { key: 'lastYear', label: 'Last Year', value: '-year' }
    ]
  }),
  computed: {
    format() {
      return this.range ? 'YYYY-MM-DD HH:mm:ss' : this.dateFormat
    },
    availableDates: () => layout.availableDates()
  },
  mounted() {
    if (this.preload) {
      this.filter = this.preload
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    save() {
      if (this.filter.date instanceof Object && !this.filter.date.end) {
        const now = moment().format('YYYY-MM-DD HH:mm:ss')
        this.filter.date.end = now
      }
      this.$emit('save', this.filter)
      this.close()
    },
    clear() {
      this.filter = {
        date: null
      }

      this.save()
    }
  }
}
</script>

<style lang="sass">
.clear-filter-text
  color: $secondary-background
$modal-card-shadow: 0 .625rem 1.25rem rgba(0, 0, 0, 0.25)
.modal-card
  box-shadow: $modal-card-shadow
.header-picker
  background-color: $date-picker-header-background-color !important
</style>
